import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Card,
  Autocomplete
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { MdArrowBack } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { confirmation_dialog, status_dialog } from "../../../utills/Alerts";
import * as API from "../../../utills/API";
import * as CommonMethods from "../../../utills/CommonMethods";
import {
  AUTO_COMPLETE_CONFIG,
  CHECK_BOX_CONFIG,
  SELECT_FIELD_CONFIG,
  TEXT_FIELD_CONFIG,
} from "../../../utills/constants/FormFieldFormats";
import * as Messages from "../../../utills/constants/Messages";
import { StaticRouterLinks } from "../../../utills/constants/StaticRouterLinks";
import useFormData from "../../../utills/hooks/useFormData";

function PincodeForm(props) {
  const params = useParams();
  let navigate = useNavigate();
  const formData = useFormData();
  const [isEdit, setEditOption] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [country_details, setCountryDetails]=useState(null);
  const [state_details, setStateDetails] = useState(null);

  let BUTTON_VALUE = isEdit
    ? CommonMethods.UPDATE_BUTTON
    : CommonMethods.CREATE_BUTTON;
  const addPincodeForm = useFormik({
    initialValues: {
      _id: null,
      pincode: "",
      country_id: "",
      state_id: "",
      city_name: "",
      division_name:"",
      district_name:"",
      country_details:"",
      state_details:"",
    },
    validationSchema: Yup.object().shape({
      _id: Yup.string().nullable(),
      pincode: Yup.number().nullable()
        .typeError(Messages?.NON_NUMBER_ERROR)
        .required(Messages?.REQUIRED_FIELD_ERROR),
      country_id: Yup.string().nullable()
          .required(Messages?.REQUIRED_FIELD_ERROR),
      state_id: Yup.string().nullable()
          .required(Messages?.REQUIRED_FIELD_ERROR),
      city_name: Yup.string().nullable()
          .required(Messages?.REQUIRED_FIELD_ERROR),
      division_name: Yup.string().nullable()
          .required(Messages?.REQUIRED_FIELD_ERROR),
      district_name: Yup.string().nullable(),
      country_details: Yup.mixed().nullable(),
      state_details: Yup.mixed().nullable(),
    }),
    onSubmit: (values) => {
      let payLoad = values;
    
      delete payLoad.country_name;
      delete payLoad.state_name;
      delete payLoad.country_details;
      delete payLoad.state_details;

      let event_info = CommonMethods.getEventInfo(
        formData.form_id,
        formData.base_path,
        BUTTON_VALUE
      );
      confirmation_dialog(
        isEdit
          ? Messages?.confirmation_messages?.pincode_update
          : Messages?.confirmation_messages?.pincode_create,
        () => {
          API.PostMethod("pincodes/new", payLoad, event_info).then((res) => {
            let status_details = {
              ...CommonMethods.STATUS_POPUP_DETAILS.success,
              message: res?.message,
            };
            status_dialog(status_details, () =>
              navigate(StaticRouterLinks.pincodes_table)
            );
          });
        }
      );
    },
  });

  useEffect(() => {
    if (params?.pincode_id) {
      setEditOption(true);
    
      API.GetMethod("pincodes/" + params?.pincode_id).then((res) => {
        
        addPincodeForm.setValues(res.data[0]);
        getStatesList(res.data[0]?.country_id);
   
        setCountryDetails(
          res.data[0]?.country_details 
        );
        setStateDetails(
          res.data[0]?.state_details 
        )
        
      });
    }
    getCountriesList();
  }, [params]);

  function getCountriesList() {
    API.GetMethod('Countries').then((result) => {
      setCountriesList(result.data);
    }, (error) => {
      setCountriesList();
    });
  }

  function getStatesList(country_id) {
    if (!CommonMethods.isNullOrUndefined(country_id)) {
      API.GetMethod('Countries/' + country_id).then(resp => {
          setStatesList(CommonMethods.isNullOrUndefined(resp['data']) ? [] : resp['data']);
      }, (error) => {
        setStatesList([]);
       
      })
    }
  }
  function backNavigate() {
    if (isEdit) {
      props.onChange();
    } else {
      navigate(StaticRouterLinks.pincodes_table);
    }
  }

  return (
    <Card className="flex-grow-1">
      <form
        className="d-grid mer-fill flex-grow-1 mer-form"
        onSubmit={addPincodeForm.handleSubmit}
      >
        <div>
          <div className="d-flex align-items-center mer-margin-top-16 mer-margin-left-16 mer-margin-right-16">
            <MdArrowBack
              className="mer-width-20 mer-height-20 mer-cursor-pointer  mer-margin-right-10 mer-icon-color"
              onClick={() => backNavigate()}
            />
            <Typography variant="h6" className="mer-icon-color">
              {isEdit ? "Edit Pin/Zip code" : "Add New Pin/Zip code"}
            </Typography>
          </div>
          <Card className="d-flex justify-content-start flex-wrap mer-margin-16 align-items-start mer-padding-top-20 secondary">
            <div className="d-flex col-md-4 col-lg-4 col-sm-12 col-xs-12 col-12">
              <div className="d-flex flex-grow-1 flex-column mer-margin-right-8 mer-margin-bottom-8">
                <TextField
                  name="pincode"
                  className="form-field"
                  label="Pin/Zip code"
                  {...TEXT_FIELD_CONFIG.OUTLINED_LABEL}
                  value={addPincodeForm.values.pincode}
                  onChange={addPincodeForm.handleChange}
                  onBlur={addPincodeForm.handleBlur}
                  error={
                    addPincodeForm.touched.pincode &&
                      addPincodeForm.errors.pincode
                      ? true
                      : false
                  }
                />
                <div className="mer-error">
                  {addPincodeForm.touched.pincode &&
                    addPincodeForm.errors.pincode ? (
                    <span className="error">
                      {addPincodeForm.errors.pincode}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="d-flex col-md-4 col-lg-4 col-sm-12 col-xs-12 col-12">
              <div className="d-flex flex-grow-1 flex-column mer-margin-right-8 mer-margin-bottom-8">
                <Autocomplete
                  name="country_id"
                  className="mer-autoComplete mer-width-percentage-100"
                  disablePortal
                  disableClearable
                  {...AUTO_COMPLETE_CONFIG.OUTLINED_LABEL}
                  options={!CommonMethods.isNullOrUndefined(countriesList) && countriesList?.length > 0 ? countriesList : []}
                  value={country_details}
                  getOptionLabel={(option) => option.country_name}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option["_id"]}>
                        <div className="d-flex flex-column">
                          <div>{option["country_name"]}</div>
                        </div>
                      </li>
                    );
                  }}
                  key={(option) => option._id}
                  onChange={(event, value) => {
                    setCountryDetails(value);
                    setStateDetails(null);
                    addPincodeForm.setFieldValue(`state_details`, null);
                    addPincodeForm.setFieldValue(`state_id`, null);
                    addPincodeForm.setFieldTouched('country_id', true, true);
                    addPincodeForm.setFieldValue('country_id', value._id);
                    addPincodeForm.setFieldValue(`country_details`, value);
                    getStatesList(value._id);
                  }}
                  renderInput={(params) => (
                    <TextField
                      name="country_id"
                      label="Country"
                      // value={addPincodeForm.values?.country_id}
                      {...params}
                      placeholder="Country"
                      error={addPincodeForm.touched?.country_id && addPincodeForm.errors?.country_id ? true : false}
                      // onChange={addPincodeForm.handleChange}
                      onBlur={addPincodeForm.handleBlur}
                    />
                  )}
                />
                <div className='mer-error'>
                  {addPincodeForm.touched?.country_id && addPincodeForm?.errors?.country_id ? 
                  <span className='error'>{addPincodeForm?.errors?.country_id}</span> : null}
                </div>
              </div>
            </div>
            <div className="d-flex col-md-4 col-lg-4 col-sm-12 col-xs-12 col-12">
              <div className='d-flex flex-grow-1 flex-column mer-margin-right-8 mer-margin-bottom-8'>
                <Autocomplete
                  name="state_id"
                  aria-labelledby='state'
                  className=' mer-autoComplete'
                  disablePortal disableClearable
                  disabled={addPincodeForm.values.country_id ? false : true}
                  {...AUTO_COMPLETE_CONFIG.OUTLINED_LABEL}
                  options={!CommonMethods.isNullOrUndefined(statesList) && statesList?.length > 0 ? statesList : []}
                  value={state_details}
                  getOptionLabel={(option) => option.state_name}
                  renderOption={(props, option) => {
                    return (
                   
                      <li {...props} key={option['_id']}>
                        
                        <div className="d-flex flex-column">
                          <div>{option['state_name']}</div>
                        </div>
                      </li>
                    );
                  }}
                  key={option => option._id}
                  onChange={
                    (event, value) => {
                    
                     
                      setStateDetails(value);
                      addPincodeForm.setFieldTouched('state_id', true, true);
                      addPincodeForm.setFieldValue('state_id', value._id);
                      addPincodeForm.setFieldValue(`state_details`, value);
                    }
                  }
                  renderInput={(params) =>
                    <TextField name='state_id'
                      label="State"
                      // value={addPincodeForm.values.state_id}
                      {...params} placeholder="Please type in or select"
                      error={addPincodeForm.values.country_id && addPincodeForm.touched.state_id && addPincodeForm.errors.state_id ? true : false}
                      // onChange={addPincodeForm.handleChange}
                      onBlur={addPincodeForm.handleBlur}
                    />}
                />
                <div className='mer-error'>
                  {addPincodeForm.values.country_id && addPincodeForm.touched.state_id 
                  && addPincodeForm.errors.state_id ? addPincodeForm.errors.state_id : null}</div>
              </div>
            </div>
            <div className="d-flex col-md-4 col-lg-4 col-sm-12 col-xs-12 col-12">
              <div className='d-flex flex-grow-1 flex-column mer-margin-right-8 mer-margin-bottom-8'>
                <TextField
                  className=''
                  aria-labelledby="division_name"
                  id="division_name"
                  name="division_name"
                  placeholder="City"
                  label="City"
                  {...TEXT_FIELD_CONFIG.OUTLINED_LABEL}
                  // onChange={addPincodeForm.handleChange}
                  onChange={(e) => {
                    addPincodeForm.handleChange(e);
                    addPincodeForm.setFieldValue('city_name', e.target.value);
                  }}
                  value={addPincodeForm.values.division_name}
                  error={
                    addPincodeForm.touched.division_name &&
                    addPincodeForm.errors.division_name ? true : false
                  }
                  onBlur={addPincodeForm.handleBlur}
                />
                <div className='mer-error'>{addPincodeForm.touched.division_name
                  && addPincodeForm.errors.division_name ?
                  addPincodeForm.errors.division_name : null}</div>
              </div>
            </div>
            <div className="d-flex col-md-4 col-lg-4 col-sm-12 col-xs-12 col-12">
              <div className='d-flex flex-grow-1 flex-column mer-margin-right-8 mer-margin-bottom-8'>
                <TextField
                  className=''
                  aria-labelledby="district_name"
                  id="district_name"
                  name="district_name"
                  placeholder="District"
                  label="District"
                  {...TEXT_FIELD_CONFIG.OUTLINED_LABEL}
                  onChange={addPincodeForm.handleChange}
                  value={addPincodeForm.values.district_name}
                  error={
                    addPincodeForm.touched.district_name &&
                      addPincodeForm.errors.district_name ? true : false
                  }
                  onBlur={addPincodeForm.handleBlur}
                />
                <div className='mer-error'>{addPincodeForm.touched.district_name
                  && addPincodeForm.errors.district_name ?
                  addPincodeForm.errors.district_name : null}</div>
              </div>
            </div>
  
          </Card>
        </div>
        <div className="d-flex justify-content-end align-self-end mer-padding-20">
          <Button
            variant="outlined"
            className="mer-margin-right-10 outlinedcls"
            onClick={() => backNavigate()}
          >
            Cancel
          </Button>
          <Button variant="contained" className="" type="submit">
            {BUTTON_VALUE}
          </Button>
        </div>
      </form>
    </Card>
  );
}

export default PincodeForm;
