import { IconButton, Menu, Card, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { confirmation_dialog, status_dialog } from "../../utills/Alerts";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { GetMethod, PostMethod } from "../../utills/API";
import {
  STATUS_POPUP_DETAILS,
  isNullOrUndefined,
} from "../../utills/CommonMethods";

const actions = [
  {
    action_name: "Download all Resumes",
    action_id: "download_all_resumes",
    confirmation_message: "Are you sure to download all resumes ?",
    button_name: "Yes",
  },
];

const MultiCandidateSelectMenu = ({ selectedList, type, onChange }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEL] = useState(null);
  const[bulkActions, setBulkActions] =  useState(actions);
  function handleOpen(e) {
    setOpen(true);
    setAnchorEL(e.target);
  }

  function handleClose() {
    setBulkActions(actions);
    setOpen(false);
    setAnchorEL(null);
  }

  async function allResumes() {
    try {
      const response = await PostMethod("candidateDetails/downloadResumes", {
        candidate_ids: selectedList,
        type,
      });
      if (!isNullOrUndefined(response?.data?.path)) {
        let zip_url = `${process.env.REACT_APP_BASE_URL}/files${response["data"]["path"]}`;
        window.open(zip_url, "_blank", "noopener,noreferrer");
      }
    } catch (err) {
    } finally {
      handleClose();
    }
  }

  useEffect(() => {
    if (open && (type == 'candidates')) {
      getBulkActions();
    }
  }, [type, open]);

  async function getBulkActions() {
    try {
      const response = await PostMethod("candidateDetails/bulkActions", {
        candidate_ids: selectedList,
      });
      if (!isNullOrUndefined(response?.data) && !isNullOrUndefined(response?.data?.bulk_actions_info)) {
        let concatinated_actions = actions.concat(response?.data?.bulk_actions_info);
        setBulkActions(concatinated_actions)
      }
    } catch (err) {
    }
  }


  function ConfirmationDialogComponent(action) {
    confirmation_dialog(
      action.confirmation_message,
      async (confirmation_response) => {
        if(action?.action_id == actions[0].action_id){
          await allResumes();
        }
        else if (!isNullOrUndefined(action.confirmation_api)) {
          await PostMethod(action.confirmation_api, {
            action: action,
            is_bulk: true,
            candidate_ids: selectedList,
            poc_details: confirmation_response
          }).then((res) => {
            if (res) {
              if (action.success_message) {
                let status_details = {
                  ...STATUS_POPUP_DETAILS.success,
                  message: action.success_message,
                };
                status_dialog(status_details, () => {
                  onChange();
                });
              
              } else {
                onChange();
              }
            }
          }).finally(()=>{
            handleClose();
          });
        } else {
          onChange();
        }
      },
      null,
      action.button_name,
      handleClose,
      true,
      action.show_poc_info
    );
  }


  return (
    <div className="d-flex align-items-center">
    {selectedList.length > 0 && <div>Selected Count: {selectedList.length} </div>}
    <Tooltip title={'Bulk Actions(Filter candidates by status for movement-related actions.)'}>
      <div>
      <IconButton disabled={selectedList.length == 0} onClick={handleOpen}>
        <BiDotsVerticalRounded className="mer-width-20 mer-height-20" />
      </IconButton>
      </div>
    </Tooltip>
    
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
      >
        <Card className="mer-theme-headermenucard mer-margin-0 mer-padding-16">
          {bulkActions.length != 0 ? (
            bulkActions.map((action, index) => (
              <Card
                key={index}
                onClick={() => {
                  ConfirmationDialogComponent(action);
                  // if (selectedList.length > 0) {
                  //   confirmation_dialog(
                  //     "Are you sure to download all resumes ?",
                  //     allResumes,
                  //     "",
                  //     "Yes",
                  //     handleClose,
                  //     true
                  //   );
                  // } else {
                  //   status_dialog(
                  //     {
                  //       ...STATUS_POPUP_DETAILS.warning,
                  //       message:
                  //         "Please select atleast one candidate to download resumes.",
                  //     },
                  //     handleClose
                  //   );
                  // }
                }}
                className="mer-action-card mer-cursor-pointer justify-content-between  d-flex flex-row"
              >
                <div>{action.action_name}</div>
              </Card>
            ))
          ) : (
            <div>No actions to perform</div>
          )}
        </Card>
      </Menu>
    </div>
  );
};

export default MultiCandidateSelectMenu;
