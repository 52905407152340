import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import {
  Select,
  Button,
  TextField,
  MenuItem,
  FormControl,
  FormLabel,
  InputAdornment,
  Typography,
  Card,
  Box,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import * as CommonMethods from "../../../utills/CommonMethods";
// import { StaticRouterLinks } from "../../../utills/constants/StaticRouterLinks";
import {
  TEXT_FIELD_CONFIG,
  SELECT_FIELD_CONFIG,
  AUTO_COMPLETE_CONFIG,
} from "../../../utills/constants/FormFieldFormats";
import { BiRupee } from "react-icons/bi";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as API from "../../../utills/API";
import {
  confirmation_dialog,
  simple_dialog,
  status_dialog,
} from "../../../utills/Alerts";
import * as Messages from "../../../utills/constants/Messages";
import { MdArrowBack } from "react-icons/md";
import useFormData from "../../../utills/hooks/useFormData";
import useDeviceProperties from "../../../utills/hooks/useDeviceProperties";
import EventEmitter from "../../../utills/EventEmitter";
import * as DateFormats from "../../../utills/DateTimeFormats";

function ProposalForm(props) {
  let deviceProperties = useDeviceProperties();
  const params = useParams();
  // const navigate = useNavigate();
  const [rolesData, setRolesData] = useState([]);
  const [geographicCategories,setGeographicCategories] = useState([]);
  const [openJoiningDatePicker, setOpenJoiningDatePicker] = useState(false);
  // const [proposalData, setProposalData] = useState([]);
  const [negotiatorsList, setNegotiatorsList] = useState([]);
  const [loading, setLoading] = useState(false);

  const formData = useFormData();
  const BUTTON_VALUE = "Submit";
  const constantFormFields = {
    role_to_be_offered_id: "",
    role_to_be_offered_info: null,
    //designation_id: "",
    joining_date: "",
    geographic_category_id: "",
    ctc_details: null,
    comments: null,
    fixed: null,
    Variable: null,
    negotiator_id: "",
    retention_bonus: 0,
    relocation_allowance: 0,
  };
  const proposalForm = useFormik({
    initialValues: constantFormFields,
    validationSchema: Yup.object().shape({
      role_to_be_offered_id: Yup.string()
        .nullable()
        .required(Messages?.REQUIRED_FIELD_ERROR),
      //designation_id: Yup.string().nullable().required(Messages?.REQUIRED_FIELD_ERROR),
      joining_date: Yup.date()
        .nullable()
        .required(Messages?.REQUIRED_FIELD_ERROR),
      geographic_category_id: Yup.string()
        .nullable()
        .required(Messages?.REQUIRED_FIELD_ERROR),
      ctc_details: Yup.number()
        .nullable()
        .max(100000000, Messages?.MAX_SALARY_ERROR)
        .min(100000, Messages?.MIN_SALARY_ERROR)
        .typeError(Messages?.NON_NUMBER_ERROR)
        .required(Messages?.REQUIRED_FIELD_ERROR),
      comments: Yup.string().nullable().max(500, Messages.MAX_CHAR),
      fixed: Yup.number()
        .nullable()
        .max(100000000, Messages?.MAX_SALARY_ERROR)
        .min(100000, Messages?.MIN_SALARY_ERROR)
        .typeError(Messages?.NON_NUMBER_ERROR)
        .required(Messages?.REQUIRED_FIELD_ERROR),
      Variable: Yup.number()
        .nullable()
        .max(100000000, Messages?.MAX_SALARY_ERROR)
        .min(0, Messages?.NON_NEGATIVE_ERROR)
        .typeError(Messages?.NON_NUMBER_ERROR)
        .required(Messages?.REQUIRED_FIELD_ERROR),
      negotiator_id: Yup.string()
        .nullable()
        .required(Messages?.REQUIRED_FIELD_ERROR),
      retention_bonus: Yup.number()
        .nullable()
        .max(100000000, Messages?.MAX_SALARY_ERROR)
        .min(0, Messages?.NON_NEGATIVE_ERROR)
        .typeError(Messages?.NON_NUMBER_ERROR)
        .required(Messages?.REQUIRED_FIELD_ERROR),
      relocation_allowance: Yup.number()
        .nullable()
        .max(100000000, Messages?.MAX_SALARY_ERROR)
        .min(0, Messages?.NON_NEGATIVE_ERROR)
        .typeError(Messages?.NON_NUMBER_ERROR)
        .required(Messages?.REQUIRED_FIELD_ERROR),
    }),
    onSubmit: (values) => {
      setLoading(true);
      let event_info = CommonMethods.getEventInfo(
        formData.form_id,
        "proposal",
        BUTTON_VALUE
      );

      confirmation_dialog(
        Messages?.confirmation_messages?.proposal_submit,
        () => {
          API.PostMethod(
            "additionalDetails/proposalDetails/" +
            (props?.id ? props.id : params?.id),
            values,
            event_info
          )
            .then((res) => {
              let status_details = {
                ...CommonMethods.STATUS_POPUP_DETAILS.success,
                message: res.message,
              };
              status_dialog(status_details, () => {
                props.onChange(true);
                EventEmitter.emit("refreshHeader", { refresh: true });
              });

              // simple_dialog(res.message, () => {
              //   props.onChange(true);
              //   EventEmitter.emit('refreshHeader', { refresh: true })
              //   // navigate(StaticRouterLinks.proposal_view + params?.id);
              // });
            })
            .catch((err) => {
              setLoading(false);
            });
        },
        null,
        null,
        () => {
          setLoading(false);
        }
      );
    },
  });

  useEffect(() => {
    API.GetMethod("designation").then((res) => {
      // setDesignationData(res.data.designations);
      res.data.designations = CommonMethods.customSort(
        res.data.designations,
        "role_name",
        "asc"
      );
      setRolesData(res?.data?.designations ? res.data.designations : []);
    });
    API.GetMethod("geographic-category").then((res) => {
      setGeographicCategories(CommonMethods.isNullOrUndefined(res?.data)?[]:res?.data)
    })
    API.GetMethod("user/filtered?is_negotiator=true").then(
      (res) => {
        setNegotiatorsList(
          CommonMethods.isNullOrUndefined(res.data.result)
            ? []
            : res.data.result
        );
      },
      (error) => {
        setNegotiatorsList([]);
      }
    );
    API.GetMethod(
      "candidateDetails/proposalDetails/" + (props?.id ? props.id : params?.id)
    ).then((res) => {
      let formFieldValues = CommonMethods.updateFormFields(
        constantFormFields,
        res.data[0]?.proposal_details
      );
      proposalForm.setValues(formFieldValues);
      const ctc = String(
        (formFieldValues?.fixed ?? 0) +
        (formFieldValues?.Variable ?? 0) +
        (formFieldValues?.retention_bonus ?? 0)
      );
      proposalForm.setFieldValue("ctc_details", ctc);
    });
  }, []);

  let variablePay = () => {
    if (
      proposalForm.values.ctc_details === null ||
      proposalForm.values.fixed === null
    ) {
      proposalForm.values.Variable = null;
    } else {
      proposalForm.values.Variable =
        +proposalForm.values.ctc_details - +proposalForm.values.fixed;
    }
    return proposalForm.values.Variable;
  };

  return (
    <div className="d-flex flex-column mer-width-percentage-100 flex-grow-1 mer-overflow">
      <Card className="d-flex flex-column">
        {props?.id ? (
          ""
        ) : (
          <div className="d-flex align-items-center mer-margin-top-16 mer-margin-left-16 mer-margin-right-16">
            <MdArrowBack
              className="mer-width-20 mer-height-20 mer-cursor-pointer  mer-margin-right-8"
              onClick={() => {
                props.onChange();
                // navigate(StaticRouterLinks.proposal_view + params?.id);
              }}
            />
            <Typography variant="h6">Edit Proposal</Typography>
          </div>
        )}
        <form
          className="d-grid flex-grow-1 mer-form"
          onSubmit={proposalForm.handleSubmit}
        >
          <div className="d-flex flex-column flex-grow-1 mer-padding-16">
            <Card className="d-flex flex-column secondary">
              <div className="mer-font-bold mer-margin-8">Proposal Details</div>
              <div className="d-flex flex-column">
                <div className="d-flex justify-content-start flex-wrap">
                  <div className="d-flex col-md-4 col-lg-4 col-sm-12 col-12 col-xs-12">
                    <div className="d-flex flex-grow-1 flex-column mer-margin-8">
                      <FormLabel id="role_to_be_offered_id">
                        Role to be Offered
                        <sup className="mer-color-red">*</sup>
                      </FormLabel>
                      <FormControl size={SELECT_FIELD_CONFIG.OUTLINED.size}>
                        {/* <Select
                        name="role_to_be_offered_id"
                        placeholder="Select role"
                        displayEmpty
                        {...SELECT_FIELD_CONFIG.OUTLINED}
                        MenuProps={SELECT_FIELD_CONFIG.MENU_PROPS}
                        value={proposalForm.values.role_to_be_offered_id}
                        onChange={proposalForm.handleChange}
                        onBlur={proposalForm.handleBlur}
                        error={
                          proposalForm.touched.role_to_be_offered_id &&
                            proposalForm.errors.role_to_be_offered_id
                            ? true
                            : false
                        }
                      >
                        <MenuItem value="">
                          <span className="selectdisabled">Select Role</span>
                        </MenuItem>
                        {!CommonMethods.isNullOrUndefined(rolesData) && rolesData?.length > 0 &&
                          rolesData.map((role) => {
                            return (
                              <MenuItem
                                className="mer-select"
                                key={role["_id"]}
                                value={role["_id"]}
                              >
                                <div>{role["role_name"]}</div>
                              </MenuItem>
                            );
                          })}
                      </Select> */}
                        <Autocomplete
                          name="role_to_be_offered_id"
                          aria-labelledby="role_to_be_offered_id"
                          className="mer-theme-auto-specialIconalign mer-autoComplete"
                          disablePortal
                          disableClearable
                          {...AUTO_COMPLETE_CONFIG.OUTLINED}
                          options={
                            !CommonMethods.isNullOrUndefined(rolesData) &&
                              rolesData.length > 0
                              ? rolesData
                              : []
                          }
                          value={proposalForm.values.role_to_be_offered_info}
                          getOptionLabel={(option) => option.role_name}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option["_id"]}>
                                <div className="d-flex flex-column">
                                  <div>{option["role_name"]}</div>
                                </div>
                              </li>
                            );
                          }}
                          key={(option) => option._id}
                          onChange={(event, value) => {
                            proposalForm.setFieldValue(
                              "role_to_be_offered_info",
                              value
                            );
                            proposalForm.setFieldTouched(
                              "role_to_be_offered_id",
                              true,
                              true
                            );
                            proposalForm.setFieldValue(
                              "role_to_be_offered_id",
                              value._id
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              name="role_to_be_offered_id"
                              // value={proposalForm.values.role_to_be_offered_id}
                              {...params}
                              placeholder="Search or type role"
                              error={
                                proposalForm.touched.role_to_be_offered_id &&
                                  proposalForm.errors.role_to_be_offered_id
                                  ? true
                                  : false
                              }
                              // onChange={proposalForm.handleChange}
                              onBlur={proposalForm.handleBlur}
                            />
                          )}
                        />
                      </FormControl>
                      <div className="mer-error">
                        {proposalForm.touched.role_to_be_offered_id &&
                          proposalForm.errors.role_to_be_offered_id
                          ? proposalForm.errors.role_to_be_offered_id
                          : null}
                      </div>
                    </div>
                  </div>

                  {/* <div className="d-flex col-md-4 col-lg-4 col-sm-12 col-12 col-xs-12">
                  <div className="d-flex flex-grow-1 flex-column mer-margin-8">
                    <FormLabel id="designation_id">
                      Select Designation
                      <sup className="mer-color-red">*</sup>
                    </FormLabel>
                    <FormControl size={SELECT_FIELD_CONFIG.OUTLINED.size}>
                      <Select
                        name="designation_id"
                        placeholder="Select designation"
                        displayEmpty
                        {...SELECT_FIELD_CONFIG.OUTLINED}
                        MenuProps={SELECT_FIELD_CONFIG.MENU_PROPS}
                        value={proposalForm.values.designation_id}
                        onChange={proposalForm.handleChange}
                        onBlur={proposalForm.handleBlur}
                        error={
                          proposalForm.touched.designation_id &&
                            proposalForm.errors.designation_id
                            ? true
                            : false
                        }
                      >
                        <MenuItem value="">
                          <span className="selectdisabled">
                            Select Designation
                          </span>
                        </MenuItem>
                        {!CommonMethods.isNullOrUndefined(designationData) && designationData?.length > 0 &&
                          designationData.map((designation) => {
                            return (
                              <MenuItem
                                className="mer-select"
                                key={designation["_id"]}
                                value={designation["_id"]}
                              >
                                <div>{designation["role_name"]}</div>
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                    <div className="mer-error">
                      {proposalForm.touched.designation_id &&
                        proposalForm.errors.designation_id ?
                        proposalForm.errors.designation_id : null}
                    </div>
                  </div>
                </div> */}

                  <div className="d-flex col-md-4 col-lg-4 col-sm-12 col-12 col-xs-12">
                    <div className="d-flex flex-grow-1 flex-column mer-margin-8 mer-datepicker">
                      <FormLabel id="joining_date">
                        Joining Date <sup className="mer-color-red">*</sup>
                      </FormLabel>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          name="joining_date"
                          placeholder="Date"
                          inputFormat="MM/dd/yyyy"
                          inputVariant="outlined"
                          open={openJoiningDatePicker}
                          onOpen={() => setOpenJoiningDatePicker(true)}
                          onClose={() => setOpenJoiningDatePicker(false)}
                          value={proposalForm.values.joining_date}
                          onChange={(newValue) => {
                            if (newValue) {
                              proposalForm.setFieldTouched("joining_date");
                              proposalForm.setFieldValue(
                                "joining_date",
                                DateFormats.formatDate(newValue)
                              );
                            }
                          }}
                          onKeyPress={() =>
                            proposalForm.setFieldTouched("joining_date")
                          }
                          renderInput={(params) => (
                            <TextField
                              className=""
                              name="joining_date"
                              {...params}
                              {...TEXT_FIELD_CONFIG.OUTLINED}
                              inputProps={{
                                ...params.inputProps,
                                readOnly: true,
                              }}
                              onClick={() => setOpenJoiningDatePicker(true)}
                              onBlur={proposalForm.handleBlur}
                              error={
                                proposalForm.touched.joining_date &&
                                  proposalForm.errors.joining_date
                                  ? true
                                  : false
                              }
                            />
                          )}
                        />
                      </LocalizationProvider>
                      <div className="mer-error">
                        {proposalForm.touched.joining_date &&
                          proposalForm.errors.joining_date
                          ? proposalForm.errors.joining_date
                          : null}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex col-md-4 col-lg-4 col-sm-12 col-12 col-xs-12">
                    <div className="d-flex flex-grow-1 flex-column mer-margin-8">
                      <FormLabel id="geographic_category">
                        Region/Geography
                        <sup className="mer-color-red">*</sup>
                      </FormLabel>
                      <FormControl size={SELECT_FIELD_CONFIG.OUTLINED.size}>
                        <Select
                          name="geographic_category_id"
                          placeholder="Geographic Category"
                          displayEmpty
                          {...SELECT_FIELD_CONFIG.OUTLINED}
                          MenuProps={SELECT_FIELD_CONFIG.MENU_PROPS}
                          value={proposalForm.values?.geographic_category_id}
                          onChange={proposalForm.handleChange}
                          onBlur={proposalForm.handleBlur}
                          error={
                            proposalForm.touched?.geographic_category_id &&
                            proposalForm.errors?.geographic_category_id
                              ? true
                              : false
                          }
                        >
                          <MenuItem value="">
                            <span className="selectdisabled">
                              Select Region/Geography
                            </span>
                          </MenuItem>
                          {!CommonMethods.isNullOrUndefined(geographicCategories) &&
                            geographicCategories?.length > 0 &&
                            geographicCategories.map((geographicCategory) => {
                              return (
                                <MenuItem
                                  className="mer-select"
                                  key={geographicCategory["_id"]}
                                  value={geographicCategory["_id"]}
                                >
                                  {geographicCategory["geographic_category_name"]}
                                </MenuItem>
                              );
                            })}

                        </Select>
                      </FormControl>
                      <div className="mer-error">
                        {proposalForm.touched?.geographic_category_id &&
                          proposalForm.errors?.geographic_category_id
                          ? proposalForm.errors?.geographic_category_id
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="d-flex justify-content-start flex-wrap">
                  <div className="d-flex col-md-4 col-lg-4 col-sm-12 col-12 col-xs-12">
                    <div className="d-flex flex-grow-1 flex-column mer-margin-8">
                      <FormLabel id="fixed">Comments</FormLabel>
                      <TextField
                        name="comments"
                        aria-labelledby="comments"
                        placeholder="Enter details here"
                        multiline
                        minRows={3}
                        {...TEXT_FIELD_CONFIG.OUTLINED}
                        value={proposalForm.values.comments}
                        onChange={proposalForm.handleChange}
                        onBlur={proposalForm.handleBlur}
                        error={
                          proposalForm.touched.comments &&
                            proposalForm.errors.comments
                            ? true
                            : false
                        }
                      />
                      <div className="mer-error">
                        {proposalForm.touched.comments &&
                          proposalForm.errors.comments
                          ? proposalForm.errors.comments
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
            {/* <Divider /> */}
            <Card className="d-flex flex-column secondary">
              <div className="mer-font-bold mer-margin-8"> Pay Details</div>
              <div className="d-flex flex-column flex-wrap">
                <div className="d-flex justify-content-start flex-wrap">
                  <div className="d-flex col-md-3 col-lg-3 col-sm-12 col-12 col-xs-12">
                    <div className="d-flex flex-grow-1 flex-column mer-margin-8">
                      <FormLabel id="fixed">
                        Fixed<sup className="mer-color-red">*</sup>
                      </FormLabel>
                      <TextField
                        name="fixed"
                        aria-labelledby="fixed"
                        placeholder="Enter details here"
                        {...TEXT_FIELD_CONFIG.OUTLINED}
                        value={proposalForm.values.fixed}
                        // onChange={proposalForm.handleChange}
                        onChange={(e) => {
                          const target = e.target.value;

                          if (!isNaN(target)) {
                            const ctc = proposalForm?.values?.ctc_details ?? 0;
                            const prev = proposalForm.values?.fixed ?? 0;

                            const ctcValue = Number(isNaN(ctc) ? 0 : ctc);
                            const prevValue = Number(isNaN(prev) ? 0 : prev);

                            const targetValue = Number(
                              isNaN(target) ? 0 : target
                            );

                            const new_value = String(
                              ctcValue + targetValue - prevValue
                            );

                            proposalForm.setFieldValue(
                              "ctc_details",
                              new_value
                            );
                          }
                          proposalForm.handleChange(e);
                        }}
                        onBlur={proposalForm.handleBlur}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <BiRupee />
                            </InputAdornment>
                          ),
                        }}
                        error={
                          proposalForm.touched.fixed &&
                            proposalForm.errors.fixed
                            ? true
                            : false
                        }
                      />
                      <div className="mer-error">
                        {proposalForm.touched.fixed && proposalForm.errors.fixed
                          ? proposalForm.errors.fixed
                          : null}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex col-md-3 col-lg-3 col-sm-12 col-12 col-xs-12">
                    <div className="d-flex flex-grow-1 flex-column mer-margin-8">
                      <FormLabel id="Variable">
                        Variable <sup className="mer-color-red">*</sup>
                      </FormLabel>
                      <TextField
                        name="Variable"
                        aria-labelledby="Variable"
                        placeholder="Enter details here"
                        {...TEXT_FIELD_CONFIG.OUTLINED}
                        //value={variablePay()}
                        value={proposalForm?.values?.Variable}
                        //onChange={proposalForm.handleChange}
                        onChange={(e) => {
                          const target = e.target.value;

                          if (!isNaN(target)) {
                            const ctc = proposalForm?.values?.ctc_details ?? 0;
                            const prev = proposalForm.values?.Variable ?? 0;

                            const ctcValue = Number(isNaN(ctc) ? 0 : ctc);
                            const prevValue = Number(isNaN(prev) ? 0 : prev);

                            const targetValue = Number(
                              isNaN(target) ? 0 : target
                            );

                            const new_value = String(
                              ctcValue + targetValue - prevValue
                            );

                            proposalForm.setFieldValue(
                              "ctc_details",
                              new_value
                            );
                          }

                          proposalForm.handleChange(e);
                        }}
                        onBlur={proposalForm.handleBlur}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <BiRupee />
                            </InputAdornment>
                          ),
                        }}
                        error={
                          proposalForm.touched.Variable &&
                            proposalForm.errors.Variable
                            ? true
                            : false
                        }
                      />
                      <div className="mer-error">
                        {proposalForm.touched.Variable &&
                          proposalForm.errors.Variable
                          ? proposalForm.errors.Variable
                          : null}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex col-md-3 col-lg-3 col-sm-12 col-12 col-xs-12">
                    <div className="d-flex flex-grow-1 flex-column mer-margin-8">
                      <FormLabel id="fixed">
                        Retention Bonus<sup className="mer-color-red">*</sup>
                      </FormLabel>
                      <TextField
                        name="retention_bonus"
                        aria-labelledby="retention_bonus"
                        placeholder="Enter details here"
                        {...TEXT_FIELD_CONFIG.OUTLINED}
                        value={proposalForm.values.retention_bonus}
                        //onChange={proposalForm.handleChange}
                        onChange={(e) => {
                          const target = e.target.value;

                          if (!isNaN(target)) {
                            const ctc = proposalForm?.values?.ctc_details ?? 0;
                            const prev =
                              proposalForm.values?.retention_bonus ?? 0;

                            const ctcValue = Number(isNaN(ctc) ? 0 : ctc);
                            const prevValue = Number(isNaN(prev) ? 0 : prev);

                            const targetValue = Number(
                              isNaN(target) ? 0 : target
                            );

                            const new_value = String(
                              ctcValue + targetValue - prevValue
                            );

                            proposalForm.setFieldValue(
                              "ctc_details",
                              new_value
                            );
                          }
                          proposalForm.handleChange(e);
                        }}
                        onBlur={proposalForm.handleBlur}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <BiRupee />
                            </InputAdornment>
                          ),
                        }}
                        error={
                          proposalForm.touched.retention_bonus &&
                            proposalForm.errors.retention_bonus
                            ? true
                            : false
                        }
                      />
                      <div className="mer-error">
                        {proposalForm.touched.retention_bonus &&
                          proposalForm.errors.retention_bonus
                          ? proposalForm.errors.retention_bonus
                          : null}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex col-md-3 col-lg-3 col-sm-12 col-12 col-xs-12">
                    <div className="d-flex flex-grow-1 flex-column mer-margin-8">
                      <FormLabel id="ctc_details">
                        CTC Details<sup className="mer-color-red">*</sup>
                      </FormLabel>
                      <TextField
                        name="ctc_details"
                        aria-labelledby="ctc_details"
                        placeholder="Enter details here"
                        {...TEXT_FIELD_CONFIG.OUTLINED}
                        value={proposalForm.values.ctc_details}
                        onChange={proposalForm.handleChange}
                        onBlur={proposalForm.handleBlur}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <BiRupee />
                            </InputAdornment>
                          ),
                        }}
                        error={
                          proposalForm.touched.ctc_details &&
                            proposalForm.errors.ctc_details
                            ? true
                            : false
                        }
                        disabled={true}
                      />
                      <div className="mer-error">
                        {proposalForm.touched.ctc_details &&
                          proposalForm.errors.ctc_details
                          ? proposalForm.errors.ctc_details
                          : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-start flex-wrap">
                  <div className="d-flex col-md-3 col-lg-3 col-sm-12 col-12 col-xs-12">
                    <div className="d-flex flex-grow-1 flex-column mer-margin-8">
                      <FormLabel id="Retention Bonus">
                        Relocation Bonus <sup className="mer-color-red">*</sup>
                      </FormLabel>
                      <TextField
                        name="relocation_allowance"
                        aria-labelledby="relocation_allowance"
                        placeholder="Enter details here"
                        {...TEXT_FIELD_CONFIG.OUTLINED}
                        value={proposalForm.values.relocation_allowance}
                        onChange={proposalForm.handleChange}
                        onBlur={proposalForm.handleBlur}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <BiRupee />
                            </InputAdornment>
                          ),
                        }}
                        error={
                          proposalForm.touched.relocation_allowance &&
                            proposalForm.errors.relocation_allowance
                            ? true
                            : false
                        }
                      />
                      <div className="mer-error">
                        {proposalForm.touched.relocation_allowance &&
                          proposalForm.errors.relocation_allowance
                          ? proposalForm.errors.relocation_allowance
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
            {/* <Divider /> */}
            <Card className="d-flex flex-column secondary">
              <div className="mer-font-bold mer-margin-8"> Negotiator</div>
              <div className="d-flex justify-content-start flex-wrap">
                <div className="d-flex col-md-4 col-lg-4 col-sm-12 col-12 col-xs-12">
                  <div className="d-flex flex-grow-1 flex-column mer-margin-8">
                    <FormLabel id="negotiator_id">
                      Negotiator
                      <sup className="mer-color-red">*</sup>
                    </FormLabel>
                    <FormControl size={SELECT_FIELD_CONFIG.OUTLINED.size}>
                      <Select
                        name="negotiator_id"
                        placeholder="Select"
                        displayEmpty
                        {...SELECT_FIELD_CONFIG.OUTLINED}
                        MenuProps={SELECT_FIELD_CONFIG.MENU_PROPS}
                        value={proposalForm.values.negotiator_id}
                        onChange={proposalForm.handleChange}
                        onBlur={proposalForm.handleBlur}
                        renderValue={(selected) => {
                          if (selected === "") {
                            return (
                              <span className="selectdisabled">
                                Select Negotiator
                              </span>
                            );
                          }
                          const selectedUser = negotiatorsList?.find(
                            (user) => user["_id"] === selected
                          );
                          return selectedUser ? selectedUser["user_name"] : "";
                        }}
                        error={
                          proposalForm.touched.negotiator_id &&
                            proposalForm.errors.negotiator_id
                            ? true
                            : false
                        }
                      >
                        <MenuItem value="">
                          <span className="selectdisabled">
                            Select Negotiator
                          </span>
                        </MenuItem>
                        {!CommonMethods.isNullOrUndefined(negotiatorsList) &&
                          negotiatorsList?.length > 0 &&
                          negotiatorsList.map((users) => {
                            return (
                              <MenuItem
                                disabled={!users["is_active"]}
                                className="mer-select"
                                key={users["_id"]}
                                value={users["_id"]}
                              >
                                <div className="d-flex flex-column">
                                  <div>{users["user_name"]}</div>
                                  <div>{users["email"]}</div>
                                </div>
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                    <div className="mer-error">
                      {proposalForm.touched.negotiator_id &&
                        proposalForm.errors.negotiator_id
                        ? proposalForm.errors.negotiator_id
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
            {/* <Divider /> */}
          </div>
          <div
            className={
              deviceProperties.isMobileDevice
                ? "d-flex align-self-end flex-column mer-padding-16"
                : "d-flex justify-content-between align-self-end align-items-center mer-padding-16"
            }
          >
            <div className="mer-color-red mer-margin-8">
              Note: All the fields marked as ‘*’ are mandatory fields.
            </div>
            <div className="d-flex justify-content-end">
              <Button
                variant="outlined"
                className="mer-margin-right-8 outlinedcls"
                // component={Link}
                // to={StaticRouterLinks.proposal_view + params?.id}
                onClick={() => {
                  props.onChange();
                }}
                disabled={loading}
              >
                Discard
              </Button>
              <Button variant="contained" className="" type="submit">
                {!loading ? (
                  BUTTON_VALUE
                ) : (
                  <Box className="d-flex">
                    <CircularProgress size="1.3rem" />
                  </Box>
                )}
              </Button>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}

export default ProposalForm;
