import * as CommonMethods from "../utills/CommonMethods";
import axios from "axios";
// import Logout from "./Logout";
import { status_dialog } from "./Alerts";
// const axios = require("axios").default;

const API_URL = process.env.REACT_APP_BASE_URL + "/api/v4/";
let isRenewingToken = false;
// let forceRefreshing = false;

export const axiosHttp = axios.create({
  baseURL: `${API_URL}`,
});

export const axiosWoI = axios.create({
  baseURL: `${API_URL}`,
});

axiosHttp.interceptors.request.use(async (request) => {
  return new Promise(async (resolve) => {
    // if (!CommonMethods.isNullOrUndefined(window.localStorage.getItem("restore_time"))) {
    //   const restore_time = new Date(window.localStorage.getItem("restore_time"));
    //   let current_time = new Date();
    //   var diff = (current_time.getTime() - restore_time.getTime()) / 1000;
    //   diff /= 60;
    //   let time_difference = Math.round(diff); // getting time difference in mins
    //   if (time_difference > 1420 && !forceRefreshing) { // 23hrs 40mins
    //     forceRefreshing = true;
    //     await Renew();
    //     // if (!forceRefreshing) {
    //     //   forceRefreshing = true;
    //     //   Renew().then((resp) => {
    //     //     forceRefreshing = false;
    //     //     if (CommonMethods.isNullOrUndefined(request.headers["Code-Verifier"])) {

    //     //       const microsoft_tokens_string = window.localStorage.getItem("microsoft_tokens");
    //     //       if (!CommonMethods.isNullOrUndefined(microsoft_tokens_string)) {
    //     //         request.headers["microsoft-tokens"] = `${microsoft_tokens_string}`;
    //     //       }

    //     //       if (!CommonMethods.isNullOrUndefined(localStorage.getItem("mer-auth-key"))) {
    //     //         request.headers["Authorization"] = `Bearer ${localStorage.getItem("mer-auth-key")}`;
    //     //       }
    //     //     }

    //     //     if (!CommonMethods.isNullOrUndefined(localStorage.getItem("mer-uuid"))) {
    //     //       request.headers["uuid"] = `${localStorage.getItem("mer-uuid")}`;
    //     //     }

    //     //     // return request;
    //     //     resolve(request)
    //     //   }).error((err) => {
    //     //     console.log(err)
    //     //   })
    //     // }
    //     // else {
    //     //   setTimeout(() => {
    //     //     if (CommonMethods.isNullOrUndefined(request.headers["Code-Verifier"])) {

    //     //       const microsoft_tokens_string = window.localStorage.getItem("microsoft_tokens");
    //     //       if (!CommonMethods.isNullOrUndefined(microsoft_tokens_string)) {
    //     //         request.headers["microsoft-tokens"] = `${microsoft_tokens_string}`;
    //     //       }

    //     //       if (!CommonMethods.isNullOrUndefined(localStorage.getItem("mer-auth-key"))) {
    //     //         request.headers["Authorization"] = `Bearer ${localStorage.getItem("mer-auth-key")}`;
    //     //       }
    //     //     }

    //     //     if (!CommonMethods.isNullOrUndefined(localStorage.getItem("mer-uuid"))) {
    //     //       request.headers["uuid"] = `${localStorage.getItem("mer-uuid")}`;
    //     //     }
    //     //     // return request;
    //     //     resolve(request)
    //     //   }, 3000);
    //     // }
    //   }
    //   else {
    //     forceRefreshing = false
    //   }
    // }
    if (CommonMethods.isNullOrUndefined(request.headers["Code-Verifier"])) {
      const microsoft_tokens_string =
        window.localStorage.getItem("microsoft_tokens");
      if (!CommonMethods.isNullOrUndefined(microsoft_tokens_string)) {
        request.headers["microsoft-tokens"] = `${microsoft_tokens_string}`;
      }

      if (
        !CommonMethods.isNullOrUndefined(localStorage.getItem("mer-auth-key"))
      ) {
        request.headers["Authorization"] = `Bearer ${localStorage.getItem(
          "mer-auth-key"
        )}`;
      }
    }

    if (!CommonMethods.isNullOrUndefined(localStorage.getItem("mer-uuid"))) {
      request.headers["uuid"] = `${localStorage.getItem("mer-uuid")}`;
    }

    const d = new Date();
    let offset = d.getTimezoneOffset();
    request.headers['offset'] = offset;
    
    resolve(request);
  });
});
axiosHttp.interceptors.response.use(
  async (response) => {
    if (
      !CommonMethods.isNullOrUndefined(
        response?.headers["microsoft-tokens-header"]
      )
    ) {
      const parsed_header = JSON.parse(
        response?.headers["microsoft-tokens-header"]
      );
      CommonMethods.setTokensToLocalStorage({
        access_token: parsed_header?.access_token,
        refresh_token: parsed_header?.refresh_token,
      });
    }
    return response;
  },

  async (error) => {
    if (!CommonMethods.isNullOrUndefined(error)) {
      if (error.code === "ERR_NETWORK") {
        let status_details = {
          ...CommonMethods.STATUS_POPUP_DETAILS.error,
          message: error?.message + "- Service not avaliable !",
        };
        status_dialog(status_details, async () => {
          return Promise.reject(error);
        });
      }

      if (
        error.response &&
        ((error.response.data && error.response.data.status === 401) ||
          error.response.status === 401)
      ) {
        if (error.response.data.is_access_expired) {
          const errorCode = error.response.data.description.split(":")[0];
          if (!isRenewingToken) {
            await Renew(errorCode);
          }
          // let status_details = {
          //   ...CommonMethods.STATUS_POPUP_DETAILS.access_restore,
          //   message: "Restoring Access. Please try again",
          // };
          // status_dialog(status_details, async () => {

          // });
          const originalRequest = error.config;
          // console.log(originalRequest)
          // Add your retry logic here (e.g., recalling the API)
          // For example, you can use a retry counter to limit the number of retries
          const maxRetries = 3;
          if (
            !originalRequest.retryCount ||
            originalRequest.retryCount < maxRetries
          ) {
            originalRequest.retryCount = originalRequest.retryCount
              ? originalRequest.retryCount + 1
              : 1;
            return new Promise((resolve) => {
              // setTimeout(() => {
              resolve(axiosHttp(originalRequest));
              // }, 2000); // Retry after 2 seconds
            });
          }
        } else {
          let status_details = {
            ...CommonMethods.STATUS_POPUP_DETAILS.error,
            message: error?.response?.data?.message,
          };
          status_dialog(status_details, async () => {
            window.localStorage.removeItem("microsoft_tokens");
            window.localStorage.removeItem("mer-auth-key");
            window.sessionStorage.clear();
            window.localStorage.clear();
            // if (window.location.pathname === '/refer/candidate') {
            // navigat('/referral/login');
            // }
            // else {
            await CommonMethods.Logout();
            // }

            //navigate("login");
          });
        }

        //return Promise.reject(error.response)
        // <CustomSnakebar verticalPosition="bottom" horizontalPosition="center" message={error.response.data.message}></CustomSnakebar>
      } else if (
        error.response &&
        error.response.data &&
        error.response.status === 403
      ) {
        return error.response;
      } else if (
        error.response &&
        ((error.response.data && error.response.data.status === 500) ||
          error.response.status === 500)
      ) {
        let status_details = {
          ...CommonMethods.STATUS_POPUP_DETAILS.error,
          message: error?.response?.data?.message,
        };
        status_dialog(status_details, () => {
          if (error?.response?.data?.is_redirect) {
            window.location.replace("/");
            return Promise.reject(error);
          } else {
            return Promise.reject(error);
          }
        });
      } else if (
        error.response &&
        ((error.response.data && error.response.data.status === 400) ||
          error.response.status === 400)
      ) {
        let status_details = {
          ...CommonMethods.STATUS_POPUP_DETAILS.error,
          message:
          error?.response?.data?.message ?? (error?.response?.data?.data && error?.response?.data?.data[0] )
        };
        status_dialog(status_details, () => {
          return Promise.reject(error);
        });
      }
      return Promise.reject(error);
      //return error.response ;
    }
  }
);

export let GetMethod = async (url) => {
  let getResponse = await axiosHttp.get(url);
  return getResponse ? getResponse.data : null;
};

export const PostMethod = async (url, post_data, event_info) => {
  // if(!isNullOrUndefined(event_info['form_id']) && !isNullOrUndefined(event_info['trigger_at'])){
  //   event_info['form-id'] = event_info['form_id'];
  //   event_info['trigger-at'] = event_info['trigger_at'];
  //   event_info['Form-Id'] = event_info['form_id'];
  //   event_info['Trigger-At'] = event_info['trigger_at'];
  // }

  const config = {
    headers: event_info,
  };

  let postData = await axiosHttp.post(url, post_data, config);
  return postData.data;
};

export const PutMethod = async (url, post_data) => {
  return {};
};

export const DeleteMethod = async (url, event_info) => {
  // if(!isNullOrUndefined(event_info['form_id']) && !isNullOrUndefined(event_info['trigger_at'])){
  //   event_info['form-id'] = event_info['form_id'];
  //   event_info['trigger-at'] = event_info['trigger_at'];
  //   event_info['Form-Id'] = event_info['form_id'];
  //   event_info['Trigger-At'] = event_info['trigger_at'];
  // }

  const config = {
    headers: event_info,
  };
  let deleteData = await axiosHttp.delete(url, config);
  return deleteData ? deleteData.data : null;
};

async function Renew(errorCode) {
  return new Promise(async (resolve, reject) => {
    if (isRenewingToken) {
      resolve(isRenewingToken);
      // return isRenewingToken; // Exit the function if a renewal is already in progress
    }

    //Handling Password change scenario if user hasn't logged out and able to get new access token
    if (errorCode === "AADSTS50173") {
      isRenewingToken = false;
      let status_details = {
        ...CommonMethods.STATUS_POPUP_DETAILS.error,
        message:
          "Your Microsoft Password has Changed, Login with your new password",
      };
      status_dialog(status_details, async () => {
        await CommonMethods.Logout();
      });
      // return isRenewingToken;
      reject(isRenewingToken);
    } else {
      isRenewingToken = true;
      //initiating silent Login each day after refresh token expiry
      try {
        const iframe = document.createElement("iframe");
        const login_url = await CommonMethods.getLoginUrl();
        iframe.src = login_url;
        iframe.sandbox = "allow-scripts allow-same-origin allow-top-navigation";
        iframe.style.display = "none";
        document.body.appendChild(iframe);
        //hit login url without user consent
        iframe.addEventListener("load", async function () {
          let responseUrl;
          try {
            responseUrl = iframe.contentWindow.location.href;
          } catch (error) {
            iframe.remove();
            // If accessing the contentWindow.location.href fails due to cross-origin restrictions OR Password change
            let status_details = {
              ...CommonMethods.STATUS_POPUP_DETAILS.error,
              message:
                "Access restoration failed. Please try refreshing. If unsuccessful, please login again",
            };
            isRenewingToken = false;
            status_dialog(status_details, async () => {
              await CommonMethods.Logout();
            });
            // return isRenewingToken;
            reject(isRenewingToken);
          }
          //get Authorization code
          const urlParams = new URLSearchParams(responseUrl.split("?")[1]);
          const code = urlParams.get("code");
          if (CommonMethods.isNullOrUndefined(code)) {
            iframe.remove();
            isRenewingToken = false;
            Renew();
            // return isRenewingToken;
            resolve(isRenewingToken);
          }
          //get new Access tokens,Refresh tokens
          let response = await CommonMethods.getTokenAndDetails(code);
          if (!CommonMethods.isNullOrUndefined(response)) {
            await localStorage.setItem("restore_time", new Date());
            let decryptedResponse = await CommonMethods.decryptContent(response?.data)
            localStorage.setItem("profile_picture_details",JSON.stringify(decryptedResponse?.profile_picture_details));
            await CommonMethods.setTokensToLocalStorage({
              access_token: decryptedResponse?.token?.access_token,
              refresh_token: decryptedResponse?.token?.refresh_token,
            });
          }
          //removing hidden iframe
          iframe.remove();
          isRenewingToken = false;
          // return isRenewingToken;
          resolve(isRenewingToken);
        });
      } catch (error) {
        console.log("Error in Renewing Access:", error);
        isRenewingToken = false;
        reject(isRenewingToken);
      }
    }
  });
}

export const PostWoIMethod = async (url, post_data, event_info) => {
  let postData = await axiosWoI.post(url, post_data, event_info);
  return postData.data;
};
