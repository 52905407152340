import React, { forwardRef, useEffect, useImperativeHandle, useContext, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import useCountryCodes from "../../../../utills/hooks/useCountryCodes";
import * as CommonMethods from "../../../../utills/CommonMethods";
import Loading from "../../../../components/Loading";
import RPPhoneInputField from "./RPPhoneInputField";
import { Card } from "@mui/material";
import * as API from "../../../../utills/API";

const POCForm = forwardRef((props, ref) => {
  const { setEnableSubmit, setPocDetails } = props;
  const { countriesWithRegexList, allowedCountries, defaultCountry, loading } = useCountryCodes();


  let constantFormFields = {
    poc_country_code: null,
    poc_mobile_number: "",
  };
  const pocForm = useFormik({
    initialValues: constantFormFields,
    validationSchema: Yup.object().shape({
      poc_country_code: Yup.string().nullable(),
      poc_mobile_number: Yup.string()
        .nullable()
        .test("is-valid-phone", CommonMethods.err_msg.mob, function (value) {
          if (
            !value ||
            CommonMethods.isNullOrUndefined(pocForm.values.poc_country_code)
          ) {
            return true;
          }
          const { path, createError } = this;
   
          const dial_code = CommonMethods.getCountryDialCode(
            countriesWithRegexList,
            pocForm.values.poc_country_code
          );
          if(dial_code && dial_code.replace("+", '') == value){
            return true
          }

          const regex = CommonMethods.getMobileNumberPattern(
            countriesWithRegexList,
            pocForm.values.poc_country_code
          );

          if (!regex) {
            return createError({ path, message: "Unsupported country code" });
          }

          const regexPattern = new RegExp(regex);
          return (
            regexPattern.test(value) ||
            createError({ path, message: CommonMethods.err_msg.mob })
          );
        }),
    }),
    onSubmit: (values) => {
      if(setPocDetails) setPocDetails(values);
    },
  });

  // Expose the `submitForm` method to the parent
  useImperativeHandle(ref, () => ({
    submitForm: pocForm.handleSubmit,
  }));

  
  useEffect(() => {
      const dial_code = CommonMethods.getCountryDialCode(
        countriesWithRegexList,
        pocForm.values.poc_country_code
      );
      let values = (dial_code && dial_code.replace("+", '') === pocForm.values.poc_mobile_number) ? constantFormFields : pocForm.values ;
      setPocDetails(values);
      setEnableSubmit(pocForm.isValid);
  }, [pocForm.isValid])

  useEffect(() =>{
    getDefaultPOCDetails();
  }, [])

 function getDefaultPOCDetails() {
   API.GetMethod("user/one").then((res) => {
     if (!CommonMethods.isNullOrUndefined(res.data)) {
       let poc_details = {
         poc_country_code: res.data.country_code ?? null,
         poc_mobile_number: res.data.mobile_number ?? "",
       }
        setPocDetails(poc_details);
        pocForm.setValues(poc_details);
     }
   });
 }


  return (
    <Card className="d-flex align-items-center justify-content-center teritary mer-padding-12 mer-margin-0 mer-margin-top-8">
      <form onSubmit={pocForm.handleSubmit}>
        <div className="d-flex flex-column">
          {!loading ? (
            <div className="d-flex flex-column gap-2 position-relative">
              <div>This mobile number will be included as the POC in the email signature. If you prefer not to add a POC, you can leave this field blank.</div>
              <RPPhoneInputField
                formikProp={pocForm}
                className="mer-field-bg-white"
                label="POC Mobile Number"
                placeholder="POC Mobile Number"
                name="poc_mobile_number"
                enableAreaCodes={false}
                country={defaultCountry}
                onlyCountries={allowedCountries}
                dependent_field={"poc_country_code"}
                mandatory={false}
                dropdownStyle={{position:"fixed"}}
              />
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              <Loading />
            </div>
          )}
        </div>
      </form>
    </Card>
  );
});

export default POCForm;

// import React, { useState } from "react";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
// import useCountryCodes from "./hooks/useCountryCodes";
// import Loading from "../components/Loading";
// import * as CommonMethods from "./CommonMethods";
// // import { isValidPhoneNumber } from "libphonenumber-js";

// const POCForm = ({phoneNumber, countryCode , onChange}) => {

//   const [error, setError] = useState("");
//   const { countriesWithRegexList, allowedCountries, defaultCountry, loading} = useCountryCodes();

//   const validatePhoneNumber= () =>{
//     if (!phoneNumber && CommonMethods.isNullOrUndefined(countryCode)) {
//         //   setError("Mobile number is required.");
//           return;
//         }

//         try {
//             const regex = CommonMethods.getMobileNumberPattern(countriesWithRegexList, countryCode);
//             console.log("regex", regex);

//             if (!regex) {
//                setError("Unsupported country code");
//                return
//             }

//             const regexPattern = new RegExp(regex);
//             let isValid = regexPattern.test(phoneNumber);
//             console.log("isValid", isValid);

//           if (!isValid) {
//             setError("Invalid phone number for the selected country.");
//           } else {
//             setError("");
//           }
//         } catch (e) {
//           setError("Invalid phone number format.");
//         }
//   }

//   // Handle phone number change
//   const handlePhoneChange = (value, { countryCode: selectedCountryCode }) => {
//     // setPhoneNumber(value);
//     // setCountryCode(selectedCountryCode);
//     console.log("changes")
//     setError("")
//     onChange(value, selectedCountryCode);
//     validatePhoneNumber(value, selectedCountryCode);
//   };

//   // Validate phone number on blur
//   const handleBlur = () => {
//     validatePhoneNumber(phoneNumber, countryCode);
//   };

//   return (
//     <div className="d-flex flex-column">
//       {!loading ? (
//         <div className="d-flex flex-column gap-2">
//           <PhoneInput
//             country={defaultCountry}
//             value={phoneNumber}
//             onChange={handlePhoneChange}
//             onBlur={handleBlur}
//             onlyCountries={allowedCountries}
//             enableAreaCodes={false}
//             countryCodeEditable={false}
//             inputStyle={{
//               width: "100%",
//               border: error ? "1px solid red" : "1px solid #ccc",
//               borderRadius: "4px",
//             }}
//           />

//           {error && (
//             <div style={{ color: "red", marginTop: "8px" }}>{error}</div>
//           )}
//         </div>
//       ) : (
//         <div className="d-flex justify-content-center">
//             <Loading />
//         </div>
//       )}
//     </div>
//   );
// };

// export default POCForm;
